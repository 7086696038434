import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`To clear a value from UserDefaults, I previously thought it was fine to do something like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`UserDefaults.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`standard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`set`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"myKey"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <p>{`Turns out that this works differently in different iOS versions. Starting in iOS 11, setting nil for a key works as I expected and the following prints nil:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`UserDefaults.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`standard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`set`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"myKey"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(UserDefaults.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`standard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"myKey"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`))`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// nil`}</span></span></code></pre>
    <p>{`However, in iOS 10, UserDefaults actually tries to serialize nil into a Data:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`UserDefaults.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`standard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`set`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"myKey"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(UserDefaults.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`standard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"myKey"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`))`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// 135 bytes`}</span></span></code></pre>
    <p>{`If we look at this data, it seems like it might be a plist:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UserDefaults.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`standard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"myKey"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)!,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`encoding`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ascii`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`))`}</span></span></code></pre>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "nohighlight"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Optional("bplist00Ô\\u{01}\\u{02}\\u{03}\\u{04}\\u{05}\\u{08}\\n\\u{0B}T$topX$objectsX$versionY$archiverÑ\\u{06}\\u{07}Troot\\0¡\\tU$null\\u{12}\\0\\u{01} _\\u{10}\\u{0F}NSKeyedArchiver\\u{08}\\u{11}\\u{16}\\u{1F}(25:<>DI\\0\\0\\0\\0\\0\\0\\u{01}\\u{01}\\0\\0\\0\\0\\0\\0\\0\\u{0C}\\0\\0\\0\\0\\0\\0\\0\\0\\0\\0\\0\\0\\0\\0\\0[")`}</span></code></pre>
    <p>{`Turns out that we can use PropertyListSerialization to actually print the serialized value of nil:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` data = UserDefaults.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`standard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"myKey"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` propertyList = `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`try`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`! PropertyListSerialization.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`propertyList`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`   `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: data,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`   `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`options`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: [],`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`   `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`format`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(propertyList)`}</span></span></code></pre>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "nohighlight"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`{`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    "$archiver" = NSKeyedArchiver;`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    "$objects" =     (`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`        "$null"`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    );`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    "$top" =     {`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`        root = "<CFKeyedArchiverUID 0x79e7c420 [0xf126e8]>{value = 0}";`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    };`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    "$version" = 100000;`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`}`}</span></code></pre>
    <p>{`For whatever reason, this was totally unexpected to me. It’s particularly tricky when you’re trying to interpret the value of a previously cleared UserDefaults key as JSON:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` data = UserDefaults.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`standard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`data`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"myKey"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` json = `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`try`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`JSONDecoder`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`().`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`decode`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(MyClass.self, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: data)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(json)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`} `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"No data found"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`This will work just fine on iOS 11+, but throw an error on iOS 10.`}</p>
    <p>{`Here’s an `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/userdefaults-set-nil-example"
      }}>{`example project`}</a>{` which demonstrates this behavior. I ended up finding one stackoverflow post about it, but as far as I can tell this isn’t documented anywhere. If you have any more info about it, `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769"
      }}>{`let me know`}</a>{` 👋`}</p>
    <p>{`Lesson learned — setting nil isn’t a good way to clear a UserDefaults value. Instead, use the recommended approach:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`UserDefaults.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`standard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`removeObject`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forKey`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"myKey"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      